import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/bio/:tid',
    name: 'BioSingle',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bio" */ '../views/Bio.vue'),
  },
  {
    path: '/bio',
    name: 'Bio',
    component: () => import(/* webpackChunkName: "bio" */ '../views/Bio.vue'),
  },
  {
    path: '/chem/:tid',
    name: 'ChemSingle',
    props: true,
    component: () => import(/* webpackChunkName: "bio" */ '../views/Chem.vue'),
  },
  {
    path: '/chem',
    name: 'Chem',
    component: () => import(/* webpackChunkName: "bio" */ '../views/Chem.vue'),
  },
  {
    path: '/show',
    name: 'Show',
    component: () => import(/* webpackChunkName: "bio" */ '../views/Show.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
