import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { bioKinds, chemKinds } from '../assets/taskDicts';
const revBioKinds = Object.assign({}, ...Object.entries(bioKinds).map(([a,b]) => ({ [b]: a })));
const revChemKinds = Object.assign({}, ...Object.entries(chemKinds).map(([a,b]) => ({ [b]: a })));

Vue.use(Vuex);

function recognizeKind (task, subject) {
  if (subject === 'bio') {
    const kinds = bioKinds;
    if (task.part === 'C') return kinds.text;
    if (!task || !task.answer || !task.largeBlocks) return undefined;
    else if (task.largeBlocks.length === 1 || /[А-Яа-я]/.test(task.answer)) return kinds.term;
    else if (task.answer.length > 4) {
      if (/[4-6]/.test(task.answer)) return kinds.sequence;
      return kinds.match;
    } else if (task.answer.length === 4) {
      if (task.largeBlocks.length === 3) return kinds.match;
      else if (task.largeBlocks[1].content.split('\n').length === 4) return kinds.sequence;
    }
    return kinds.pick;
  } else if (subject === 'chem') {
    const kinds = chemKinds;
    if (task.part === 'C') return kinds.text;
    if (!task || !task.answer || !task.largeBlocks) return undefined;
    else if (task.largeBlocks.length === 1) return kinds.term;
    else if (task.largeBlocks.length === 2) return kinds.pick;
    return kinds.match;
  }

  return undefined;
}

export default new Vuex.Store({
  state: {
    task: undefined,
    previousTask: undefined,
    count: {
      current: 0,
      total: 0,
    },
    tables: {},
  },
  mutations: {
    setTask (state, task) { state.task = task; },
    updateTaskParam (state, { param, value }) { if (state.task) Vue.set(state.task, param, value); },
    setPreviousTask (state, ptask) { state.previousTask = ptask; },
    setTotalCount (state, count) { state.count.total = count; },
    incrementCurrentCount(state, n) { if (state.count.current < state.count.total) state.count.current += n; },
    addTable (state, table) { state.tables[table.name] = table.content; },
    clearTables (state) { state.tables = {}; },
  },
  actions: {
    getTaskWithMissingInfo: async function ({ state, commit }, { subject }) {
      commit('clearTables');

      return new Promise((resolve) => {
        axios.get(`/api/v1/task/missing?subject=${subject}`)
          .then((response) => {
            if (response.data.status && response.data.status === 'finished') {
              const message = 'Все задания рассортированы!';
              commit('setTask', { task: message, answer: 'Ура! 🎉', largeBlocks: [{ content: message, key: nanoid() }] });
              resolve(state.task);
              return;
            }
            const task = response.data;
            task.largeBlocks = task.task
              .split('\n\n')
              .map(lb => ({ content: lb, key: nanoid() }));
            if (task.kind) {
              if (task.subject === 'bio' && bioKinds.hasOwnProperty(task.kind)) task.kind = bioKinds[task.kind];
              else if (task.subject === 'chem' && chemKinds.hasOwnProperty(task.kind)) task.kind = bioKinds[task.kind];
            } else task.kind = recognizeKind(task, task.subject);
            if (task.theme && task.theme.length === 2) {
              task.chapter = task.theme[0];
              task.topic = task.theme[1];
            }

            commit('setTask', task);

            resolve(state.task);
          });
      });
    },
    getTaskById: async function ({ state, commit }, { taskId }) {
      return new Promise((resolve) => {
        axios.get(`/api/v1/task/${taskId}`)
          .then((response) => {
            const task = response.data;
            task.largeBlocks = task.task
              .split('\n\n')
              .map(lb => ({ content: lb, key: nanoid() }));
            if (task.kind) {
              if (task.subject === 'bio' && bioKinds.hasOwnProperty(task.kind)) task.kind = bioKinds[task.kind];
              else if (task.subject === 'chem' && chemKinds.hasOwnProperty(task.kind)) task.kind = bioKinds[task.kind];
            } else task.kind = recognizeKind(task, task.subject);
            if (task.theme && task.theme.length === 2) {
              task.chapter = task.theme[0];
              task.topic = task.theme[1];
            }

            commit('setTask', task);

            resolve(state.task);
          });
      });
    },
    updateTask: async function ({ state, commit }) {
      let revKinds;
      if (state.task.subject === 'bio') revKinds = revBioKinds;
      else if (state.task.subject === 'chem') revKinds = revChemKinds;

      const task = {
        task: state.task.largeBlocks.map(lb => lb.content).join('\n\n'),
        answer: state.task.answer || '',
        kind: revKinds[state.task.kind] || '',
        position: state.task.position || '',
        theme: [state.task.chapter || '', state.task.topic || ''],
        removed: !!state.task.removed,
        hasWarning: !!state.task.hasWarning,
        hasError: !!state.task.hasError,
      };

      return new Promise ((resolve) => {
        axios.patch(`/api/v1/task/${state.task._id}`, { task })
          .then((response) => {
            resolve(response.data);
          });
      });
    },
    updateCount: async function ({ state, commit }, { subject }) {
      if (!state.count.total) return axios.get(`/api/v1/task/missing/left?subject=${subject}`)
        .then((response) => {
          commit('setTotalCount', response.data);
          commit('incrementCurrentCount', 1);
        });

      commit('incrementCurrentCount', 1);

      return true;
    },
    nextTask: async function ({ state, commit, dispatch }) {
      await dispatch('updateTask');
      commit('setPreviousTask', state.task._id);
      await dispatch('getTaskWithMissingInfo', { subject: 'chem' });
      await dispatch('updateCount', { subject: 'chem' });
      return true;
    },
    prevTask: async function ({ state, commit, dispatch }) {
      await dispatch('getTaskById', { taskId: state.previousTask });
      commit('incrementCurrentCount', -1);
      commit('setPreviousTask', undefined);
    },
    getTable: async function ({ state, commit }, tabName) {
      if (state.tables[tabName]) return true;

      return axios.get(`/api/v1/table?name=${tabName}`)
        .then((response) => {
          commit('addTable', response.data);
        });
    },
  },
});
