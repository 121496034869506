
const bioKinds = {
  term: 'Термин/число',
  pick: 'Множ. выбор',
  match: 'Сопоставление',
  sequence: 'Последовательность',
  text: 'Развернутый ответ',
};

const bioPositions = new Set([
  ...[...Array(21).keys()].map(bp => `B${bp + 1}`),
  ...[...Array(7).keys()].map(bp => `C${bp + 1}`),
]);

const bioPositionsRules = {
  'Биология как наука': new Set(['B1', 'B2', 'B7', 'B8', 'B19', 'B20', 'C1', 'C2', 'C3']),
  'Цитология': new Set(['B1', 'B3', 'B4', 'B5', 'B7', 'B8', 'B9', 'B10', 'B19', 'B20', 'B21', 'C1', 'C2', 'C3', 'C4', 'C6']),
  'Генетика': new Set(['B6', 'B7', 'B8', 'B19', 'B20', 'B21', 'C1', 'C3', 'C7']),
  'Зоология': new Set(['B1', 'B3', 'B4', 'B5', 'B7', 'B8', 'B9', 'B10', 'B11', 'B19', 'B19', 'B20', 'B21', 'C1', 'C2', 'C3', 'C4']),
  'Ботаника': new Set(['B1', 'B3', 'B7', 'B8', 'B9', 'B10', 'B11', 'B19', 'B19', 'B20', 'B21', 'C1', 'C2', 'C3', 'C4']),
  'Анатомия': new Set(['B1', 'B4', 'B5', 'B7', 'B8', 'B12', 'B13', 'B14', 'B20', 'B21', 'C1', 'C2', 'C3', 'C4']),
  'Эволюция': new Set(['B1', 'B7', 'B8', 'B15', 'B16', 'B19', 'B19', 'B20', 'B21', 'C1', 'C2', 'C3', 'C5']),
  'Экология': new Set(['B1', 'B17', 'B18', 'B19', 'B19', 'B20', 'B21', 'C1', 'C2', 'C3', 'C5']),
  'Термин/число': new Set(['B1', 'B2', 'B3', 'B6']),
  'Множ. выбор': new Set(['B4', 'B7', 'B9', 'B12', 'B15', 'B17', 'B20', 'B21']),
  'Сопоставление': new Set(['B5', 'B8', 'B10', 'B13', 'B16', 'B18']),
  'Последовательность': new Set(['B11', 'B14', 'B19']),
  'Развернутый ответ': new Set(['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7']),
  'term': new Set(['B1', 'B2', 'B3', 'B6']),
  'pick': new Set(['B4', 'B7', 'B9', 'B12', 'B15', 'B17', 'B20', 'B21']),
  'match': new Set(['B5', 'B8', 'B10', 'B13', 'B16', 'B18']),
  'sequence': new Set(['B11', 'B14', 'B19']),
  'text': new Set(['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7']),
};

const bioThemes = [
  {
    chapter: 'Биология как наука',
    themes: [
      'Биологические науки',
      'Ученые биологии',
      'Методы биологии',
      'Свойства живого',
      'Уровни организации живого',
    ],
  },
  {
    chapter: 'Цитология',
    themes: [
      'Неорганические вещества',
      'Органические вещества',
      'Строение клеток',
      'Матричные процессы',
      'Метаболизм',
      'Деление клеток',
      'Эмбриогенез',
      'Вирусы и бактерии',
      'Сравнение царств',
      'Хромосомные наборы',
    ],
  },
  {
    chapter: 'Генетика',
    themes: [
      'Методы генетики',
      'Независимое наследование',
      'Аутосомное сцепление',
      'Сцепление с полом',
      'Задачи на родословные',
    ],
  },
  {
    chapter: 'Зоология',
    themes: [
      'Подцарство Простейшие',
      'Тип Кишечнополостные',
      'Тип Плоские черви',
      'Тип Круглые черви',
      'Тип Кольчатые черви',
      'Тип Членистоногие',
      'Тип Моллюски',
      'Группа Рыбы и низшие Хордовые',
      'Класс Амфибии',
      'Класс Рептилии',
      'Класс Птицы',
      'Класс Млекопитающие',
      'Отряды Млекопитающих',
      'Широкие сравнения',
    ],
  },
  {
    chapter: 'Анатомия',
    themes: [
      'Ткани',
      'Опорно-двигательный аппарат',
      'Пищеварительная система',
      'Витамины и минеральные вещества',
      'Нервная система',
      'Органы чувств',
      'Кровь',
      'Иммунная система',
      'Сердечно-сосудистая система',
      'Дыхательная система',
      'Эндокринная система',
      'Выделительная система',
      'Половая система и развитие',
    ],
  },
  {
    chapter: 'Ботаника',
    themes: [
      'Ткани',
      'Вегетативные органы',
      'Группа Водоросли',
      'Группа Высшие споровые',
      'Отдел Голосеменные',
      'Генеративные органы и размножение',
      'Отдел Покрытосеменные',
      'Царство Грибы и Лишайники',
    ],
  },
  {
    chapter: 'Эволюция',
    themes: [
      'Эволюционные теории',
      'Микроэволюция и макроэволюция',
      'Критерии вида',
      'Доказательства эволюции',
      'Геохронология и антропогенез',
      'Селекция и биотехнология',
    ],
  },
  {
    chapter: 'Экология',
    themes: [
      'Факторы среды',
      'Пищевые цепи',
      'Экосистемы',
      'Учение о биосфере',
      'Среды обитания',
      'Влияние человека на природу',
    ],
  },
];


const chemKinds = {
  term: 'Число',
  pick: 'Множ. выбор',
  match: 'Сопоставление',
  text: 'Развернутый ответ',
};

const chemPositions = new Set([
  ...[...Array(29).keys()].map(bp => `B${bp + 1}`),
  ...[...Array(6).keys()].map(bp => `C${bp + 1}`),
]);

const chemPositionsRules = {
  'Основы химии': new Set(['B1', 'B2', 'B3', 'B4', 'B19', 'B20', 'B21', 'B22', 'B23', 'B24', 'C1', 'C2']),
  'Неорганическая химия': new Set(['B5', 'B6', 'B7', 'B8', 'B9', 'B10', 'C3']),
  'Органическая химия': new Set(['B11', 'B12', 'B13', 'B14', 'B15', 'B16', 'B17', 'B18', 'C4']),
  'Химия и жизнь': new Set(['B25', 'B26', 'B27', 'B28', 'B29', 'C5', 'C6']),
  'Число': new Set(['B27', 'B28', 'B29']),
  'Множ. выбор': new Set(['B1', 'B2', 'B3', 'B4', 'B6', 'B7', 'B10', 'B12', 'B13', 'B14', 'B15', 'B18', 'B19', 'B20']),
  'Сопоставление': new Set(['B5', 'B8', 'B9', 'B11', 'B16', 'B17', 'B21', 'B22', 'B23', 'B24', 'B25', 'B26']),
  'Развернутый ответ': new Set(['C1', 'C2', 'C3', 'C4', 'C5', 'C6']),
  'term': new Set(['B27', 'B28', 'B29']),
  'pick': new Set(['B1', 'B2', 'B3', 'B4', 'B6', 'B7', 'B10', 'B12', 'B13', 'B14', 'B15', 'B18', 'B19', 'B20']),
  'match': new Set(['B5', 'B8', 'B9', 'B11', 'B16', 'B17', 'B21', 'B22', 'B23', 'B24', 'B25', 'B26']),
  'text': new Set(['C1', 'C2', 'C3', 'C4', 'C5', 'C6']),
};

const chemThemes = [
  {
    chapter: 'Основы химии',
    themes: [
      'Строение атомов',
      'Периодический закон',
      'Степень окисления и валентность',
      'Виды связей',
      'Кристаллические решетки',
      'Виды реакций',
      'Реакции ионного обмена',
      'ОВР',
      'Скорость реакций',
      'Химическое равновесие',
      'Гидролиз',
      'Электролиз',
    ],
  },
  {
    chapter: 'Неорганическая химия',
    themes: [
      'Классификация и номенклатура',
      'Свойства металлов',
      'Свойства неметаллов',
      'Свойства оксидов',
      'Свойства оснований',
      'Свойства кислот',
      'Свойства солей',
      'Микс',
      'Короткие цепочки',
      'Длинные цепочки',
    ],
  },
  {
    chapter: 'Органическая химия',
    themes: [
      'Гомологи и изомеры',
      'Гибридизация и связи',
      'Классификация и номенклатура',
      'Углеводороды',
      'Спирты, фенолы и простые эфиры',
      'Альдегиды и кетоны',
      'Карбоновые кислоты',
      'Амины и аминокислоты',
      'Биоорганика',
      'Микс',
      'Короткие цепочки',
      'Длинные цепочки',
    ],
  },
  {
    chapter: 'Химия и жизнь',
    themes: [
      'Работа в лаборатории',
      'Разделение смесей и очистка веществ',
      'Качественные реакции',
      'Промышленные процессы',
      'Полимеры',
      'Задачи',
    ],
  },
];


export { bioKinds, bioPositions, bioPositionsRules, bioThemes,
  chemKinds, chemPositions, chemPositionsRules, chemThemes };
